// eslint-disable-next-line no-shadow
export enum DbCode {
  BDI = 'BDI',
  CAF = 'CAF',
  CIV = 'CIV',
  GNQ = 'GNQ',
  MRT = 'MRT',
  TCD = 'TCD',
  COD = 'COD',
  TGO = 'TGO',
  BEN = 'BEN',
  MDG = 'MDG',
  COG = 'COG',
  ACO = 'ACO',
  CMR = 'CMR',
  SEN = 'SEN',
  GAB = 'GAB',
  UNI = 'UNI',
  PCM = 'PCM',
  MLI = 'MLI',
  LBR = 'LBR',
  NER = 'NER',
  GIN = 'GIN',
  BFA = 'BFA',
  GHA = 'GHA',
  RWA = 'RWA',
  UNKNOWN = 'UNKNOWN',
}
