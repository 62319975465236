import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr';

  interface DatePickerProps {
    id?: string
    label: string | any;
    handleValueSelected?: any;
  }

  const locales = ['en', 'fr', 'de', 'ru', 'ar-sa'] as const;
  const CustomDatePicker: React.FC<DatePickerProps> = ({ id, label, handleValueSelected}) => {
  const [locale, setLocale] = React.useState<typeof locales[number]>('fr');
  const [value, setValue] = React.useState<Date | null>(null);

  return (
    <LocalizationProvider  adapterLocale={locale} dateAdapter={AdapterDayjs}>
      <DatePicker        
        label= {label}
        value={value}
        onChange={(newValue: any) => {
          setValue(newValue);
          handleValueSelected(id, newValue);
        }}
        renderInput={(params: any) => <TextField
                                  sx={{ width: 300 }} {...params} />}
      />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;