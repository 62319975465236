import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardItem from './cardItem';

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  border: 'none',
}));

export default function subMenuStatsClaimsGrid() {
  const menuData = [
    {
      title: 'Sinistres-Pays',
      description: 'Edition des sinistres par Pays',
      road: 'countryclaims',
      back: true,
      queryType:1
    },
    {
      title: 'Sinistres-Souscripteur',
      description: 'Edition des sinistres par Souscripteur',
      road: 'policyholderclaims',
      back: true,
      queryType:2
    },
    {
      title: 'Sinistres-Assureur',
      description: 'Edition des sinistres par Assureur',
      road: 'insurerclaims',
      back: true,
      queryType:3
    },
    {
      title: 'Sinistres-Pays(Sum up)',
      description: 'Obtenir le total Sinistres sans détail',
      road: 'countryclaimssum',
      back: true,
      queryType:4
    }
  ]
  return (
    <Box
      sx={{
        display: 'grid',
        columnGap: 3,
        rowGap: 1,
        gridTemplateColumns: 'repeat(auto-fit, minmax(var(--item-width), 1fr)',
      }}>
      <Grid container sx={{ justifyContent: 'space-evenly' }} spacing={{  xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {Array.from(Array(menuData.length)).map((_, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <Item sx={{ flexShrink: 1, maxWidth: '20rem' }}>
              <CardItem menuData={menuData[index]} />
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}