import React, { Fragment, useState } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


interface StatsByContratStep2FormProps {
    contractsData?: any;
    policiesData?: any;
    onChange?: any;
    onChangeD?: any;
    onSubmit?: any;
    fileReady?: any;
}


const StatsByContratStep2Form: React.FC<StatsByContratStep2FormProps> = ({ policiesData, onChange, onChangeD, onSubmit }) => {

    const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
    const [checked, setChecked] = React.useState(false);
    const [policiesList, setPolicies] = useState(policiesData);
    const [fileReady, setFileStatus] = useState(false);
    const [downloadStarted, setDownloadStarted] = useState(false);
    const [errorOccured, setErrorOccured] = useState(false);
    const [finalLink, setLink] = useState(document.createElement('a'));
    let url = '';
    let link: HTMLAnchorElement = document.createElement('a');


    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});


    const handlePolicies = () => {

        return steps.length;
      };
    
      const completedSteps = () => {
        return Object.keys(completed).length;
      };
    
    


    const getDefaultValue = ((id: string, val: any) => {

    });





    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };



    const download = (() => {
        finalLink.click();
    })

    return (
        <>
            <Grid container component="form"> 
            <Button size="small" onClick={onSubmit}>  Valider </Button>

            </Grid>
        </>
    );
}   

export default StatsByContratStep2Form;


