import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions }  from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';


interface Data {
  name: string;
  value: string;
}


interface LimitedAutocompleteprops {
  id: string | any;
  label: string | any;
  value?: any;
  selectedOptions: any[];
  setSelectedOptions: any;
  children?: any;
  errorText?: string;
  maxSelections: number;
  options: any;
}


const LimitedAutocomplete: React.FC<LimitedAutocompleteprops> = ({ id,label,maxSelections, selectedOptions, setSelectedOptions, errorText, options}) => {
  const [open, setOpen] = React.useState(false);
  //const [options, setOptions] = React.useState<readonly Data[]>([]);
  
  const [filterOption, setFilterOption] = React.useState(false);
  const loading = open && options.length === 0;

  // React.useEffect(() => {
  //   let active = true;

  //   if (!loading) {
  //     return undefined;
  //   }
  //   if(filterSelectedOptions){
  //     setFilterOption(filterSelectedOptions)
  //   }

  //   (async () => {
  //     if (active) {
  //       dataArray?.length > 0 ? setOptions([...dataArray]) :  setOptions([]) ;
  //     }
  //   })();

  //   return () => {
  //     active = false;
  //   };
  // }, [loading, dataArray, filterSelectedOptions]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: Data) => option.name,
  });

  return (
    <Autocomplete
      id={id}
      multiple
      sx={{ width: 300 }}
      open={open}
      filterOptions={filterOptions}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(e, value, reason) => {
        console.log('inside the onChange===', reason, selectedOptions.length);
        if(reason === 'removeOption'){
          errorText = "";
        }
        setSelectedOptions(value)
    }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      getOptionDisabled={(option) => (selectedOptions.length === maxSelections || selectedOptions.includes(option.name) ? true : false)}
      options={options}
      value={selectedOptions}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          helperText={selectedOptions.length === maxSelections && errorText === "" ? "Maximum number of selections have been made." : errorText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default LimitedAutocomplete;



