// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    --page-size: 30rem;
  }

  .container {
    transform: translateY(calc(30vh - 50%));
    padding: 2rem;
    border: solid 1px rgba(0, 0, 0, 0.08);
    border-radius: 0.25rem;
  }

  .icon-invalid {
    color: grey;
  }

  .icon-valid {
    color: var(--mdc-theme-primary);
  }
`, "",{"version":3,"sources":["webpack://./src/components/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,uCAAuC;IACvC,aAAa;IACb,qCAAqC;IACrC,sBAAsB;EACxB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,+BAA+B;EACjC","sourcesContent":[":host {\n    --page-size: 30rem;\n  }\n\n  .container {\n    transform: translateY(calc(30vh - 50%));\n    padding: 2rem;\n    border: solid 1px rgba(0, 0, 0, 0.08);\n    border-radius: 0.25rem;\n  }\n\n  .icon-invalid {\n    color: grey;\n  }\n\n  .icon-valid {\n    color: var(--mdc-theme-primary);\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
