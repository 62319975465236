import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';



// export const typeDefs = gql`
//   extend type Query {
//     isLoggedIn: Boolean!
//     cartItems: [ID!]!
//   }
// `;

// /**
//  * Render our app
//  * - We wrap the whole app with ApolloProvider, so any component in the app can
//  *    make GraphqL requests. Our provider needs the client we created above,
//  *    so we pass it as a prop
//  * - We need a router, so we can navigate the app. We're using Reach router for this.
//  *    The router chooses between which component to render, depending on the url path.
//  *    ex: localhost:3000/login will render only the `Login` component
//  */

//  const IS_LOGGED_IN = gql`
//  query IsUserLoggedIn {
//    isLoggedIn @client
//  }
// `;

// // function IsLoggedIn() {
// //  const { data } = useQuery(IS_LOGGED_IN);
// //  return data.isLoggedIn ? <Pages /> : <Login />;
// // }


// // Set up our apollo-client to point at the server we created
// // this can be local or a remote endpoint
// const client = getApolloClient();




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
