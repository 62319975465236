import React, { Fragment } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
export default function PageContainer (props: any) {
  return (
    <Fragment>
      <Container>{props.children}</Container>
    </Fragment>
  );
}

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    maxWidth: 1250,
    margin: '0 auto',
    padding: 8 * 3,
  });
  