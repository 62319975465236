import React, { useState } from 'react';

import './styles.css';
import Box from '@mui/material/Box/Box';
import FormControl from '@mui/material/FormControl/FormControl';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import IconButton from '@mui/material/IconButton/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput';
import Button from '@mui/material/Button/Button';
import Stack from '@mui/material/Stack/Stack';
import { isEmailValid } from '../utils/groupBy';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface Login {
  name: string;
  value: string;
}


const API_URL = process.env.REACT_APP_API_URL || 'http://164.92.171.197:5010';

const Login = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [valid, setValidity] = useState(false);
    const [email, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();  // Hook to handle navigation


   // Fonction pour gérer le login via API
   const loginUser = async (email: string, password: string) => {
    try {
      const response = await axios.post(API_URL + '/login/', { email, password }, { responseType: 'json' });
      console.log('Login success:', response.data);

      // Stocker le token (si nécessaire)
      localStorage.setItem('token', response.data.token);

      // Redirection vers la page "home" après login
      navigate('/stats');
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  
// Gestion de la soumission du formulaire
const handleSubmit = (e: React.FormEvent) => {
  e.preventDefault();
  if (valid) {
    loginUser(email, password);
  }
};


  // Gestion des changements dans les champs
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    if (id === 'email') {
      setUserName(value);
      setValidity(isEmailValid(value) && password !== '');
    } else if (id === 'password') {
      setPassword(value);
      setValidity(isEmailValid(email) && value !== '');
    }
  };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    return(

        <div className="container">
    
          <h1>Please Log In</h1>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <form onSubmit={handleSubmit}>
            <div>
          <FormControl sx={{ m: 2, width: '40ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-username">Utilisateur</InputLabel>
          <OutlinedInput
            id="email"
            type='email'
            label="Utilisateur"
            onChange={onChange}
          />
        </FormControl>
        </div>
        <div>
        <FormControl sx={{ m: 2, width: '40ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-password">Password</InputLabel>
          <OutlinedInput
            id="password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onChange={onChange}
            label="Password"
          />
        </FormControl>
        </div>
    <div>
    <FormControl sx={{ m: 2, width: '40ch' }} variant="outlined">
        <Stack direction="row" spacing={2}>
            {
                valid ?<Button variant="outlined" onClick={handleSubmit}>CONNEXION</Button>: <Button variant="outlined" disabled>CONNEXION</Button>
            }
            <Button variant="outlined" disabled>
                MOT DE PASSE OUBLIE
            </Button>

        </Stack>
    </FormControl>
    </div>
    
          </form>
          </Box>
        </div>
    
      )


    }

    export default Login;