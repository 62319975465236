import { useState } from 'react';

export const useForm = (callback: any, initialState: any = {}) => {
  const [values, setValues] = useState(initialState);

  const onChangeCommon = (event: any) => {
    console.log("   inside    ",event)
    setValues({
      ...values,
      [event.target.name]: event.target.value,
      validity: checkValidity(values)
    });
  };

  const onChangeCustom = (event: any, val: any) => {

    if([event.target][0] !== undefined){
      setValues({
        ...values,
        [event.target.name]: val,
        validity: checkValidity(values)
      });
    }else{
      if(event === 'prime'){
        setValues({
          ...values,
          'prime': val,
          validity: checkValidity(values)
        });
      }else if(event === 'policies'){
        setValues({
          ...values,
          'policies': val,
          validity: checkValidity(values)
        });
      }else if(event === 'policyholder'){
        console.log("   inside    ",val)
        setValues({
          'policyholder': val,
          ...values,
          validity: checkValidity(values)
        });
      }else{
        setValues({
          ...values,
          [event]: val,
          validity: checkValidity(values)
        });
      }
    }
  };

  const onSimpleChange = (id: string, val: any) => {
    setValues({
      ...values,
      [id]: val,
      validity: checkValidity(values)
    });
  };


  const onSubmit = (event: any) => {
    event.preventDefault();
    callback();
  };


  return {
    onChangeCommon,
    onChangeCustom,
    onSimpleChange,
    onSubmit,
    checkValidity,
    values
  };
};

/**
 * to make a field unvalid set it to an empty string
 * @param values 
 * @returns 
 */
function checkValidity(values: any) {
  const {validity, ...rest} = values ;

  const val = Object.values(rest);
  const props = Object.keys(rest);
  const checkDate = (props.find((e)=> e === "startDate") !== undefined) && (props.find((e)=> e === "endDate") !== undefined)  ;
  if(checkDate && rest["checkInterval"] ){
    if (rest["startDate"] !=="" && rest["endDate"] !==""){
      /**
       * checkAboveMonthLimit check if the date are above a limit
       */
      const aboveDaysLimit = ( (Math.abs(rest["startDate"] - rest["endDate"] ) /1000)/86400 ) > 92;
      if((rest["startDate"] > rest["endDate"] ) ||  aboveDaysLimit ){
        return [false];
      }
    
    }else{
      return [false]
    }
  }
  const check = val.flatMap(el => {
    if (typeof el === "string" && el.length === 0) {
      return false;
    }
    return true;
  });
  if(rest['fakePolicyholder'] ){
    check[0] = true;
    console.log("inside fakecheckPolicyholder ", check)
  }
  return check;
}