import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import MembersReportForm from '../components/membersReport-form';


interface membersreportProps { }

const MembersreportForm: React.FC<membersreportProps> = () => {
const API_URL = process.env.REACT_APP_API_URL || 'https://alpha-api.dev.pactilis.com';
  const [contracts, setContracts]= useState(null);
  useEffect(()=>{
    axios(API_URL+'/contracts').then((response)=>{
      setContracts(response.data)
    }).catch((error)=>{

      console.log('==========response from acxios', error)
    })
  });

  return (
    <Fragment>

     <MembersReportForm  contractsData={contracts}/>  
      

    </Fragment>
  )
}
export default MembersreportForm;