import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardItem from './cardItem';

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  border: 'none',
}));

export default function subMenuStatsGrid() {
  const menuData = [
    {
      title: 'Rapport',
      description: 'Edition de rapport avec graphes',
      road: 'statreport',
      back: true
    },
    {
      title: 'Sinistres',
      description: 'Edition le liste de sinistres',
      road: 'claimsreport',
      back: true
    },
    {
      title: 'Beneficiaires',
      description: 'Edition le liste de bénéficiaires',
      road: 'membersreport',
      back: true
    }
  ]
  return (
    <Box
      sx={{
        display: 'grid',
        columnGap: 3,
        rowGap: 1,
        gridTemplateColumns: 'repeat(auto-fit, minmax(var(--item-width), 1fr)',
      }}>
      <Grid container sx={{ justifyContent: 'space-evenly' }} spacing={{  xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {Array.from(Array(menuData.length)).map((_, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <Item sx={{ flexShrink: 1, maxWidth: '20rem' }}>
              <CardItem menuData={menuData[index]} />
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}