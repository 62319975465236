export const DateType = [
    {
        value: 'DS',
        name: 'DATE DE SOINS'
    },
    {
        value: 'DC',
        name: 'DATE COMPTABLE'
    },
    {
        value: 'DR',
        name: 'DATE REGLEMENT'
    }
]

export const ModelReport = [
    {
        value: 'PAC',
        name: 'PACTILIS'
    },
    {
        value: 'ASC',
        name: 'ASCOMA'
    }
]

export const ModelReportFormatMembers = [
    {
        value: 2,
        name: 'CONTRATS INTERNATIONAUX'
    },
    {
        value: 'ASC',
        name: 'ASCOMA'
    }
]

export const ModelReportFormatClaims = [
    {
        value: 1,
        name: 'CLIENTS INTERNATIONAUX'
    },
    {
        value: 3,
        name: 'PRESTATIONS COMPLETES'
    }
]

export const ModelReportFormatStatistics = [
    {
        value: 1,
        name: 'MODELE SYNTHETIQUE'
    },
    {
        value: 2,
        name: 'MODELE DETAILLE'
    }

]

export const ClaimsType = [
    {
        value: 'TP',
        name: 'TP'
    },
    {
        value: 'HTP',
        name: 'HTP'
    },
    {
        value: 'HO',
        name: 'HO'
    },
    {
        value: 'GR',
        name: 'GR'
    }
]

export const Languages = [
    {
        value: 'FR',
        name: 'Francais'
    },
    {
        value: 'EN',
        name: 'English'
    }
]


export const ClaimsStatus = [
    {
        value: 'P',
        name: 'Payé'
    },
    {
        value: 'S',
        name: 'Saisie'
    }
]

export const DiagnosisLanguage = [
    {
        value: 'EN',
        name: 'EN'
    },
    {
        value: 'EN_OG',
        name: 'EN_OG'
    },
    {
        value: 'ICD9',
        name: 'ICD9'
    },
    {
        value: 'DGN_DESC',
        name: 'DGN_DESC'
    }
]

export const reportLanguages = [
    {
        value: 'FR',
        name: 'Francais'
    },
    {
        value: 'EN',
        name: 'English'
    }
]


export const YesOrNo = [
    {
        value: true,
        name: 'OUI'
    },
    {
        value: false,
        name: 'NON'
    }
]

export const AllMembersOrActive = [
    {
        value: true,
        name: 'TOUS'
    },
    {
        value: false,
        name: 'UNIQUEMENT ACTIFS'
    }
]

export const NowOrInterval = [
    {
        value: false,
        name: 'Dès ce jour'
    },
    {
        value: true,
        name: 'Intervalle'
    }
]


export const Currency = [
    {
        value: 'EUR',
        name: 'EUR'
    },
    {
        value: 'XAF',
        name: 'XAF'
    },
    {
        value: 'XOF',
        name: 'XOF'
    },
    {
        value: 'USD',
        name: 'USD'
    },
    {
        value: 'RWF',
        name: 'RWF'
    },
    {
        value: 'MGA',
        name: 'MGA'
    },
    {
        value: 'GNF',
        name: 'GNF'
    },
]

export const ComparaisonTypes = [
    {
        value: 'regime',
        name: 'REGIME'
    },
    {
        value: 'country',
        name: 'PAYS'
    }
]