import React, { Fragment, useState } from 'react';
import axios from 'axios';

import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import CustomAutocomp from './customAutocomplete';
import DatePicker from './basicDatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ComparaisonTypes } from '../common/form-const';



const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });
interface StatsByContratStep1FormProps {
    contractsData?: any;
    policiesData?: any;
    onChange?: any;
    onChangeD?: any;
    onChangePolComp?: any;
    fileReady?: any;
}


const StatsByContratStep1Form: React.FC<StatsByContratStep1FormProps> = ({contractsData, policiesData, onChange, onChangeD, onChangePolComp }) => {

    const steps = ['Select campaign settings', 'Create an ad group'];
    const [checked, setChecked] = React.useState(false);
    const [policiesList, setPolicies] = useState([]);
    const [compType, setCompType] = useState(0);
    const [fileReady, setFileStatus] = useState(false);
    const [downloadStarted, setDownloadStarted] = useState(false);
    const [errorOccured, setErrorOccured] = useState(false);
    const [finalLink, setLink] = useState(document.createElement('a'));
    let url = '';
    let link: HTMLAnchorElement = document.createElement('a');


    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});


    const handlePolicies = () => {

        return steps.length;
      };
    
      const completedSteps = () => {
        return Object.keys(completed).length;
      };
    
    
      const chooseRegimePolicies = (event: any, val: any) => {
        setPolicies([]);
        console.log('event from choseRegimePolices', event, val.policies);
        setPolicies(val.policies);      
        
      };

    const getDefaultValue = ((id: string, val: any) => {

    });





    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const onChangeComparaisonType = ((event: any, val: any) => {
        console.log('event from onChangeComparaisonType', event, val);
        if(val.value === 'regime'){
            setCompType(1)
        }else{
            setCompType(2)
        }
    })


    const download = (() => {
        finalLink.click();
    })

    return (
        <>
            <Grid container component="form">        
                <Grid item xs={6}>
                    <FormControl sx={{ m: 2 }}>
                        <CustomAutocomp id='comparaison_type' label='Comparaison' multiple={false} dataArray={ComparaisonTypes} handleValueSelected={onChangeComparaisonType}  />
                        <FormHelperText id="outlined-weight-helper-text">Selectionner le type de comparaison </FormHelperText>
                    </FormControl>

                </Grid>
            {    compType === 1 ?            <Grid item xs={6}>
                    <FormControl sx={{ m: 2 }}> 
                        <CustomAutocomp id='policyholder' label='Souscripteur' multiple={false} dataArray={contractsData} handleValueSelected={chooseRegimePolicies} />
                        <FormHelperText id="outlined-weight-helper-text">Selectionner le souscripteur</FormHelperText>

                    </FormControl>

                </Grid> : "" }
            </Grid>

            {   <Grid container component="form">       
                <Grid item xs={6}>

                        <FormControl sx={{ m: 2 }}> 
                            <DatePicker id='startDate_0' label='Date début' handleValueSelected={onChangeD} />
                            <CustomWidthTooltip title="La date selectionnée concerne l'intervalle le plus ancien">
                                <FormHelperText id="outlined-weight-helper-text">Indiquer la date de Début de la période N - 1</FormHelperText>
                            </CustomWidthTooltip>
                        </FormControl>

                        </Grid>
                        <Grid item xs={6}>

                        <FormControl sx={{ m: 2 }}>
                            <DatePicker id='endDate_0' label='Date fin' handleValueSelected={onChangeD} />
                            <CustomWidthTooltip title="La date selectionnée concerne l'intervalle le plus ancien">
                                <FormHelperText id="outlined-weight-helper-text">Indiquer la date de Fin de la période N - 1</FormHelperText>
                            </CustomWidthTooltip>
                        </FormControl>

                        </Grid>
                        </Grid>
                        }
           { compType === 1 ?  <Grid container component="form"> 
                    {policiesData.map((policy: { value: React.Key | null | undefined}, index: number) => {
                return (<Grid item key={policy.value}>
                    <FormControl sx={{ m: 2 }}>
                        <CustomAutocomp id={policy.value} label='Police' multiple={false} dataArray={policiesList} handleValueSelected={onChangePolComp} defaultValue={policiesList[index] ??null } />
                        <FormHelperText id="outlined-weight-helper-text">La police selectionnée sera comparée avec: {policy.value}</FormHelperText>
                    </FormControl>
                </Grid>)
            })}

            </Grid> : ""}
        </>
    );
}   

export default StatsByContratStep1Form;


