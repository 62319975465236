import React from 'react';
import { Link, Route, Routes } from "react-router-dom";
import Home from './pages';
import './App.css';
import SubMenuStats from './pages/subMenuStats';
import SubMenuStatsGrid  from './pages/subMenuStat';
import Navbar from './components/navbar';
import StatsByContratForm from './components/statsReport-form';
import SubMenuClaims from './components/subMenuClaims';
import  ClaimsReport  from './components/claimsReportForm';
import  MembersReport  from './components/membersReportForm';
import  PagesContainer  from './components/page-container';
import  Login  from './components/login';


export default function App() {
  return (
    <><Navbar />
    <PagesContainer>
      <Routes>
        <Route>
          <Route index element={<Login />} />
          <Route path="home" element={<Home />} />
          <Route path="stats" element={<SubMenuStats />}/>
          <Route  path="/stats/statreport" element={<SubMenuStatsGrid />}/>
          <Route  path="/stats/statreport/policyholderstats/:queryParam" element={<StatsByContratForm />}/>
          <Route  path="/stats/statreport/countrystats/:queryParam" element={<StatsByContratForm />}/>
          <Route  path="stats/claimsreport" element={<SubMenuClaims />}/>
          <Route  path="stats/claimsreport/insurerclaims/:queryType" element={<ClaimsReport />}/>
          <Route  path="stats/claimsreport/countryclaims/:queryType" element={<ClaimsReport />}/>
          <Route  path="stats/claimsreport/countryclaimssum/:queryType" element={<ClaimsReport />}/>
          <Route  path="stats/claimsreport/policyholderclaims/:queryType" element={<ClaimsReport />}/> 
          <Route path="stats/membersreport" element={<MembersReport />} />
          <Route path="stats/statreport" element={<SubMenuStatsGrid />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </PagesContainer>
</>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}