import React, { Fragment, useEffect, useState } from 'react';
import * as formConsts from '../common/form-const';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import CustomAutocomp from '../components/customAutocomplete';
import DatePicker from '../components/basicDatePicker';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useForm } from '../utils/hooks'
import { Alert, AlertTitle, CircularProgress } from '@mui/material';
import axios from 'axios';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Road = styled(Link)({
  textDecoration: 'none',
});


interface ClaimsFormProps {
  //login: (a: { variables: LoginTypes.LoginVariables }) => void;    
  insurerData?: any;
  contractsData?: any;
  queryParam?: string;
  country?: any;
  policies?: any;
  policiesGrouped? : any;
  allPolicies?: any;}

const ClaimsForm: React.FC<ClaimsFormProps> = ({ contractsData,  policies, country, policiesGrouped, queryParam, allPolicies }) => {
  const [countriesData, setPolicies] = useState([]);
  const [reportValues, setReportValues] = useState({
    startDate: '',
    endDate: '',
    validity: [false],
    claimDateType: '',
    claimsType: '',
    diagLang: '',
    claimsStatus: '',
    regroup:'',
    allPolicies: allPolicies,
    policies: policies,
    policiesGrouped: policiesGrouped,
    modele: '',
    reportLang:''
  });
  let url = '';
  let link: HTMLAnchorElement = document.createElement('a');
  const [fileReady, setFileStatus] = useState(false);
  const [downloadStarted, setDownloadStarted] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [finalLink, setLink] = useState(document.createElement('a'));
  const { values, onChangeCustom, onSimpleChange, onSubmit, checkValidity } = useForm(claimsReportCallback, reportValues)

  useEffect(() => {
    switch (queryParam) {

      case "1":
        if (values.allPolicies){
          values.policies = ["0"];
          values.validity = checkValidity(values);
        }else{
          const pol = policies.map((o: any) => o['value']);
          values.country = (country !== undefined && country !== "") ? { value: country.dbCode, name: country.dbCode } : "";
          values.policies = pol.length > 0 ? pol : "";
          values.validity = checkValidity(values);
          values.allPolicies = allPolicies;

        }
        break;
      case "2":
        values.policies = policies.length > 0 ? policies : "";
        values.validity = checkValidity(values);
        values.allPolicies = allPolicies;
        break;
      case "3":

        break;
      case "4":
        values.country = (country !== undefined && country !== "") ? { value: country.dbCode, name: country.dbCode } : "";
        values.validity = checkValidity(values);
        values.allPolicies = allPolicies;
        break;
      default:
        break;
    }

  }, [queryParam, policies, country, values, checkValidity]);

  function claimsReportCallback() {
    let sources: any[] = [];
    setDownloadStarted(true);
    setFileStatus(false);
    
    let urlString="/reports/claims";
    let fileName = "CLAIMS_"+values.startDate+ "_"+ values.endDate +".xlsx";

    let claimsStatusArray: any[] = [];
    let claimsTypeArray: any[] = [];
    values.claimsStatus.forEach((element: any) => {
      claimsStatusArray.push(element.value)
    });
    values.claimsType.forEach((element: any) => {
      claimsTypeArray.push(element.value)
    });

    if (queryParam === "2") {
      let countries: any[] = [];
      values.policies.forEach((element: any) => {
        countries.push(element.name.substring(0, 3));
      });
      countries = Array.from(new Set(countries));
      countries.forEach((coun: string) => {
        let localPolicies: string[] = [];
        values.policies.forEach((pol: any) => {
          if (pol.name.substring(0, 3) === coun) {
            localPolicies.push(pol.name.substring(3))
          }
        });
        sources.push({
          dbCode: coun,
          policyIds: localPolicies,
          startDate: values.startDate,
          endDate: values.endDate,
        })
      })
    } else {
      
      if(allPolicies){
        urlString="/reports/claimsAllPolicies";
        fileName =`CLAIMS_${values.country.value}_${values.startDate}_${ values.endDate}.zip`;
      }

      sources.push({
        dbCode: values.country.value,
        policyIds: values.policies,
        startDate: values.startDate,
        endDate: values.endDate,
      })
    }

    const API_URL = process.env.REACT_APP_API_URL || 'http://164.92.171.197:5010';
    axios.post(API_URL +urlString , {
      sources: sources,
      language: values.diagLang.value,
      claimStatus: claimsStatusArray,
      payWho: claimsTypeArray,
      claimDateType: values.claimDateType.value,
      regroup: values.regroup.value?? false,
      policiesGrouped: policiesGrouped?? [], 
      queryType: queryParam,
      allPolicies: allPolicies,
      modele: values.modele.value,
      reportLang: values.reportLang.value

    }, {
      responseType: 'blob'
    }).then((response) => {
      setDownloadStarted(false);
      url = window.URL.createObjectURL(new Blob([response.data]));
      link.href = url;
      if(allPolicies){
        link.setAttribute('download', fileName);
      }else{
        link.setAttribute('download', fileName);

      }
      document.body.appendChild(link);
      setLink(link);
      setFileStatus(true);
    }).catch((error) => {
      setErrorOccured(true);
      setDownloadStarted(false);
    });
  }


  const onChange = ((event: any, val: any) => {
    console.log("values=====", values)
    setErrorOccured(false);
    setFileStatus(false);
    const id = String(event.target.id).split('-')[0];
    values[`${String(event.target.id).split('-')[0]}`] = val;
    if (id === 'policyholder') {
      onChangeCustom(event, val.value);
      setPolicies(val.policies);
      setFileStatus(false);
    }
    onChangeCustom(event, val);

  });

  const onChangeD = ((id: string, val: any) => {
    values[id] = moment(val.toString()).format('YYYY-MM-DD');
    onSimpleChange(id, moment(val.toString()).format('YYYY-MM-DD'));
    setErrorOccured(false);
  });

  const download = (() => {
    setErrorOccured(false);
    finalLink.click();
  })


  return (
    <Fragment>
      {errorOccured ? <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Une erreur est survenue  — <strong>Veuillez contacter l'administrateur!</strong>
      </Alert> : null}
      <Paper sx={{ marginTop: 3 }}>
        <FormControl component="fieldset">

          <Grid container component="form" >
            {
              queryParam === "3" ? (
                <><Grid item xs={6}>
                  <FormControl sx={{ m: 2 }}>
                    <CustomAutocomp id='policyholder' label='Souscripteur' multiple={false} dataArray={contractsData} handleValueSelected={onChange} />
                    <FormHelperText id="outlined-weight-helper-text">Selectionner le souscripteur</FormHelperText>
                  </FormControl>
                </Grid><Grid item xs={6}>
                    <FormControl sx={{ m: 2 }}>
                      <CustomAutocomp id='policies' label='Pays' multiple={true} dataArray={countriesData} handleValueSelected={onChange} filterSelectedOptions={true} />
                      <FormHelperText id="outlined-weight-helper-text">Pays du rapport</FormHelperText>
                    </FormControl>
                  </Grid></>) : (<></>)
            }
            <Grid item xs={6}>
              <FormControl sx={{ m: 2 }}>
                <DatePicker id='startDate' label='Date début' handleValueSelected={onChangeD} />
                <FormHelperText id="outlined-weight-helper-text">Indiquer la date de Début</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ m: 2 }}>
                <DatePicker id='endDate' label='Date fin' handleValueSelected={onChangeD} />
                <FormHelperText id="outlined-weight-helper-text">Indiquer la date de Fin</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ m: 2 }} >
                <CustomAutocomp id='claimDateType' label='Type de date' dataArray={formConsts.DateType} handleValueSelected={onChange} />
                <FormHelperText id="outlined-weight-helper-text">Type de date Comptable ou Soins</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ m: 2 }} >
                <CustomAutocomp id='claimsType' label='Type de soins' filterSelectedOptions multiple dataArray={formConsts.ClaimsType} handleValueSelected={onChange} />
                <FormHelperText id="outlined-weight-helper-text">TP, HTP, GO</FormHelperText>
              </FormControl>
            </Grid>
            {
              queryParam !== "4" ? (
                <Grid item xs={6}>
                <FormControl sx={{ m: 2 }} >
                  <CustomAutocomp id='diagLang' label='Language de traduction' dataArray={formConsts.DiagnosisLanguage} handleValueSelected={onChange} />
                  <FormHelperText id="outlined-weight-helper-text">Affections traduites en :</FormHelperText>
                </FormControl>
              </Grid>) : (<></>)
            }

            <Grid item xs={6}>
              <FormControl sx={{ m: 2 }} >
                <CustomAutocomp id='claimsStatus' label='Status FDS' filterSelectedOptions multiple dataArray={formConsts.ClaimsStatus} handleValueSelected={onChange} />
                <FormHelperText id="outlined-weight-helper-text">P, S</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ m: 2 }} >
                <CustomAutocomp id='regroup' label='Repartir/Regrouper'   dataArray={formConsts.YesOrNo} handleValueSelected={onChange} />
                <FormHelperText id="outlined-weight-helper-text">Regrouper (oui) les polices d'un régime sur une seule page</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ m: 2 }} >
                <CustomAutocomp id='modele' label='Modèle'   dataArray={formConsts.ModelReportFormatClaims} handleValueSelected={onChange} />
                <FormHelperText id="outlined-weight-helper-text">Modèle de l'extraction</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ m: 2 }} >
                <CustomAutocomp id='reportLang' label='Langue du rapport'   dataArray={formConsts.Languages} handleValueSelected={onChange} />
                <FormHelperText id="outlined-weight-helper-text">Langue du rapport</FormHelperText>
              </FormControl>
            </Grid>

          </Grid>

        </FormControl>
        <Box sx={{ alignSelf: 'flex-end', alignContent: "flex-end", marginLeft: 'auto' }}>
          {values && values.validity.find((v: any) => {  return v === false }) === undefined ? <Button size="small" onClick={onSubmit}>  Valider </Button> : ''}
          {downloadStarted ? <CircularProgress color="inherit" size={20} /> : null}
          {fileReady ? <Button size="small" onClick={download}>  Telecharger </Button> : ''}
          <Road to='/' >
            <Button size="small">  Annuler </Button>
          </Road>
        </Box>
      </Paper>
    </Fragment>
  );

}

export default ClaimsForm;