import React , { Fragment } from 'react';
import MenuGrid from '../components/menu-grid';


interface menuGridProps  {}

const menuGrid: React.FC<menuGridProps> = () => {

    return (
        <Fragment>            
            <MenuGrid />
        </Fragment>
    )

  }

  export default menuGrid;