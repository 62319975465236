import React , { Fragment } from 'react';
import  SubMenuStatsGrid from '../components/submenustats-stats-grid';


interface submenuStatsGridProps {}

const submenustatsReportGrid: React.FC<submenuStatsGridProps> = () => {

    return (
        <Fragment>            
            <SubMenuStatsGrid />
        </Fragment>
    )

  }

  export default submenustatsReportGrid;