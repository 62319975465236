import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

import { COUNTRIES } from '../common/countries';
// import CustomAutocomp from '../components/customAutocomplete';
// import DatePicker from '../components/basicDatePicker';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import StatsByContratStep0Form from  '../components/statsReport_step0';
import StatsByContratStep1Form from  '../components/statsReport_step1';
import StatsByContratStep2Form from  '../components/statsReport_step2';
import { useForm } from '../utils/hooks';
import { groupBy } from '../utils/groupBy';
import Alert from '@mui/material/Alert';
import { Link, useParams } from 'react-router-dom';
import StepButton from '@mui/material/StepButton/StepButton';
import Step from '@mui/material/Step/Step';
import Stepper from '@mui/material/Stepper/Stepper';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';

//TODO
// puger les polices en cas de doublons meme police meme pays
interface StatsByContratFormProps {
    contractsData?: any;
    fileReady?: any;
}


const StatsByContratForm: React.FC<StatsByContratFormProps> = () => {
    let contract = {'name': "-", 'value': "-", 'policies': [], 'insurer': "-", 'administrator': "-"}
    const API_URL = process.env.REACT_APP_API_URL || 'http://164.92.171.197:5010';
    const [checked, setChecked] = React.useState(true);
    const [countriesData, setPolicies] = useState([]);
    const [reportParams, setReportParams] = useState({});
    const [policiesPremiums, setPoliciesPremiums] = useState([]);
    const [premiumsFields, setShowPremiumFields] = useState(false);
    const [prime, setPrime] = useState(0)
    const [regime, setRegime] = useState(contract);
    const [contracts, setContracts] = useState([]);
    const [policiesComparison, setPoliciesComparison] = useState([]);
    const [fileReady, setFileStatus] = useState(false);
    const [loadingFile, setLoadingStatus] = useState(false);
    const [downloadStarted, setDownloadStarted] = useState(false);
    const [errorOccured, setErrorOccured] = useState(false);
    const [finalLink, setLink] = useState(document.createElement('a'));
    const params = useParams();

    
    let url = '';
    let link: HTMLAnchorElement = document.createElement('a');
    
    //const steps = ['Paramètres du rapport', 'Configuration du Modèle', `Validate`];
    const steps = ['Paramètres du rapport', 'Configuration du Modèle',];

    const [activeStep, setActiveStep] = React.useState(0);    
    const [queryParam, setQueryParam] = useState(params.queryParam);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});
    interface Source {
        dbCode: string;
        policyIds: string[];
        interval: {
          startDate: string;
          endDate: string;
          position: number;
        };
        allPolicies: boolean;
      }
      
    useEffect(() => {
        switch (queryParam) {
    
          case "1":
            console.log("=================================", queryParam)
            const fetchData = async () => {
                const result = await axios(
                  API_URL + '/contracts',
                );
      
                setContracts(result.data);
              };
      
              fetchData();
            break;
          case "2":
              console.log("=================queryParms", queryParam)
            break;
          case "3":
    
            break;
          default:
            break;
        }
    
      }, [queryParam, API_URL]);

    const { values, onChangeCustom, onSimpleChange, onSubmit } = useForm(StatsByContratCallback, {
        policyholder: '',
        policies: [],
        policiesPremiums: [],
        startDate: '',
        endDate: '',
        startDate_0: moment().format('YYYY-MM-DD'),
        endDate_0: moment().format('YYYY-MM-DD'),
        endExeDate: '',
        language: '',
        model: {value: 'ASC', name: 'ASCOMA'},        
        modelReportFormatStatistics: 0,
        country: 'INT',
        fakePolicyholder:  Number(queryParam) === 2,
        policiesComparison: [],
        checkInterval:false,
        allPolicies: false,
        validity: [false]
    });
    function StatsByContratCallback() {
        setLoadingStatus(true);
        setErrorOccured(false);
        console.log('=================',values)
        let policyIds: any[] = [];
        let sources: any[] = [];
        if(Number(queryParam) !== 2){
            values.policies.forEach((policy: any) => {
                const country = COUNTRIES.find((co: any) => {
                    return co.countries?.find((country: any) => {
                        return country.value === policy.value.slice(0, 3)
                    })
                });
                policyIds.push({
                    dbCode: country?.dbCode,
                    policy: policy.value.slice(3, policy.length)
                })
            });} else{
              if(values.allPolicies){
                  policyIds.push({
                      dbCode: values.country,
                      policy: "0",
                      allPolicies: values.allPolicies
      
                  })
              }else{
                values.policies.forEach((policy: any) => {
                    policyIds.push({
                        dbCode: values.country,
                        policy:  policy.slice(3, policy.length)
                    })
                })

              }
            }
        let statP = groupBy(policyIds, 'dbCode');

        if (values.policiesComparison.length !== 0) {
            values.policiesComparison.forEach((pol:any)=>{

                const policy_N0_dbCode = pol.policy_N0.slice(0, 3);
                const policy_N1_dbCode = pol.policy_N1.slice(0, 3);
            
                const interval0 = {
                  startDate: moment(values.startDate_0).format('YYYY-MM-DD'),
                  endDate: moment(values.endDate_0).format('YYYY-MM-DD'),
                  position: 0
                };
            
                const interval1 = {
                  startDate: moment(values.startDate).format('YYYY-MM-DD'),
                  endDate: moment(values.endDate).format('YYYY-MM-DD'),
                  position: 1
                };

                const keys = Object.keys(pol)
                console.log("==============keys",keys, JSON.stringify(pol))
                for(const key of keys){
                    if(key === 'policy_N1'){
                        if (sources.length === 0){
                            sources.push({
                                dbCode: policy_N1_dbCode,
                                policyIds: [pol.policy_N1.slice(3)],
                                interval: {
                                  startDate:  interval1.startDate ,
                                  endDate:  interval1.endDate ,
                                  position: interval1.position
                                },
                                allPolicies: values.allPolicies                             
                            })
                        }else{
                            let source1Index = sources.findIndex((s: any) => s.dbCode === policy_N1_dbCode && s.interval.position === 1);
                            console.log('==========source1Index', source1Index, policy_N1_dbCode, JSON.stringify(sources))
                            let source1:Source = {
                                dbCode: '',
                                policyIds: [],
                                interval: {
                                    startDate: '',
                                    endDate: '',
                                    position: 1
                                },
                                allPolicies: values.allPolicies 
                            };
                            if (source1Index !== -1) {
                                source1 = sources[source1Index];
                            }
                            source1.policyIds = [...source1.policyIds, pol.policy_N1.slice(3)];
                            source1.policyIds = Array.from(new Set(source1.policyIds));
                            source1.interval = {
                                startDate: interval1.startDate,
                                endDate: interval1.endDate,
                                position: interval1.position
                            };
                            source1.dbCode = policy_N1_dbCode;
                            if (source1Index !== -1) {
                              sources[source1Index] = source1;
                            }else{
                              sources.push(source1)
                            }
                        }
                    }else{ 
                            let source0Index = sources.findIndex((s: any) => s.dbCode === policy_N0_dbCode && s.interval.position === 0);
                            let source0:Source = {
                                dbCode: '',
                                policyIds: [],
                                interval: {
                                    startDate: '',
                                    endDate: '',
                                    position: 0
                                },
                                allPolicies: values.allPolicies 
                            };
                            if (source0Index !== -1) {
                                source0 = sources[source0Index];
                            }
                            source0.policyIds = [...source0.policyIds, pol.policy_N0.slice(3)];
                            source0.policyIds = Array.from(new Set(source0.policyIds));
                            source0.interval = {
                                startDate: interval0.startDate,
                                endDate: interval0.endDate,
                                position: interval0.position
                            };
                            source0.dbCode = policy_N0_dbCode;
                           if (source0Index !== -1) {
                                sources[source0Index] = source0;

                            }else{
                                sources.push(source0);

                            }

                        
                    }
                    }
                }
            )
          } else {
            if ( values.allPolicies ){
            
              sources.push({
                dbCode: values.country,
                policyIds: [],
                interval: {
                  startDate: moment(values.startDate).format('YYYY-MM-DD'),
                  endDate: moment(values.endDate).format('YYYY-MM-DD'),
                  position: 1
                },
                allPolicies: values.allPolicies
              });

            }else{
              for (const [key, value] of Object.entries(statP)) {
                const dbCode = key;
                const pol = (value as any[]).map((el) => el.policy);
                const filteredPol = Array.from(new Set(pol));
                console.log('====================', filteredPol);
            
                sources.push({
                  dbCode: dbCode,
                  policyIds: filteredPol,
                  interval: {
                    startDate: moment(values.startDate).format('YYYY-MM-DD'),
                    endDate: moment(values.endDate).format('YYYY-MM-DD'),
                    position: 1
                  },
                  allPolicies: values.allPolicies
                });
              }

            }
          }
          
    setPoliciesComparison(values.policiesComparison)

    
    const API_URL = process.env.REACT_APP_API_URL || 'http://164.92.171.197:5010';
    let  policyholderName =  '';
    if (Number(queryParam) === 2){
         policyholderName =  String('ASCOMA_').concat(values.country);
    }else{
         policyholderName =  String(values.policyholder.name).split("-")[0] || '-';
    }
    
    var data = JSON.stringify({
        policyholderId: values.policyholder.value || '',
        policyholderName: policyholderName,
        policies: [],
        startDate:  moment(values.startDate).format('YYYY-MM-DD'),
        endDate: moment(values.endDate).format('YYYY-MM-DD'),
        startDate_0:  moment(String(values.startDate_0).length === 0  ? new Date() : values.startDate_0  ).format('YYYY-MM-DD'),
        endDate_0: moment(String(values.endDate_0).length === 0  ? new Date() : values.endDate_0 ).format('YYYY-MM-DD'),
        startExeDate: new Date(values.startExeDate).getTime(),
        endExeDate: new Date(values.endExeDate).getTime(),
        language: values.language.value,
        currency: values.currency.value,
        model: values.model.value,
        insurer:values.policyholder.insurer || '-',
        administrator: values.policyholder.administrator || '-',
        broker: values.policyholder.broker || '-',
        policiesComparison: values.policiesComparison,
        sources: sources,
        configurations: [],
        policiesPremiums: values.policiesPremiums,
        allPolicies: values.allPolicies
    })
          
    console.log('there are the sources ===',data)
    
    axios.get(  API_URL + '/reports/statistics',{
        params: data,
        responseType: "blob"
      } ).then((response) => {
        setDownloadStarted(false);
        url = window.URL.createObjectURL(new Blob([response.data]));
        link.href = url;
        link.setAttribute('download', String(policyholderName).concat('_')
            .concat(moment(values.startDate).format('DD-MM-YYYY')).concat('_AU_')
            .concat(moment(values.endDate).format('DD-MM-YYYY'))
            .concat(".xlsx"));
        document.body.appendChild(link);
        //link.click();
        setLink(link);
        setFileStatus(true);
        setLoadingStatus(false);
    }).catch((error) => {
        console.log("=========================Error!", error)
        setLoadingStatus(false);
        setErrorOccured(true);
        setFileStatus(false);
        setDownloadStarted(false);
    })

    }

    const onChange = ((event: any, val: any) => {
        setErrorOccured(false);
        setFileStatus(false);
        if( typeof (event) !== 'string' ){
            console.log(" typeof (event)=======",event)
            const id = String(event.target.id).split('-')[0];
            values[`${String(event.target.id).split('-')[0]}`] = val;
            if (id === 'policyholder') {
                onChangeCustom('policyholder', val.value);
                setPolicies(val.policies);
                onChangeCustom( 'policies' , val.policies);
            }else if (id ==='prime'){
                setPrime(Number(val));
                onChangeCustom( 'prime' , Number(event.target.value))
            }
        }

        else{
            
            if(Number(queryParam) === 2 && event === 'policyholder'){
                console.log(' am inside the change',event === 'policyholder', val)
                onChangeCustom('policyholder',contract );
                setRegime(contract);
            }else{
                onChangeCustom(event, val);
            }
        }
        

        console.log('inside the onChange==',values, event, val)
    });

    const onChangeD = ((id: string, val: any) => {
        values[id] = moment(val.toString()).format('YYYY-MM-DD');
        onSimpleChange(id, moment(val.toString()).format('YYYY-MM-DD'));
        setErrorOccured(false);
    });

    const onChangePolComp = ((event: any, val: any) => {    
        const indexFound = values['policiesComparison']?.findIndex((e:any)=> e.policy_N0 === String(event.target.id).split('-')[0])
        if (indexFound !== -1){
            values['policiesComparison'][indexFound] = {
                    "policy_N1": String(event.target.id).split('-')[0],
                    "policy_N0":    val.value
                        }
        }else{
            values['policiesComparison'].push({
            "policy_N1": String(event.target.id).split('-')[0],
            "policy_N0":    val.value
             })
            }
            setPoliciesComparison(values['policiesComparison'])
        console.log('inside the onChangePolComp==', String(event.target.id).split('-')[0], values['policiesComparison'], val, indexFound)
    });




    const totalSteps = () => {
        return steps.length;
      };
    
      const completedSteps = () => {
        return Object.keys(completed).length;
      };
    
      const isLastStep = () => {
        return activeStep === totalSteps() - 1;
      };
    
      const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
      };
      

      useEffect(()=>{
        axios(API_URL+'/contracts').then((response)=>{
          setContracts(response.data)
        })
      });
 const handleNext = () => {
    
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    if(newActiveStep === 1){
        values['startDate_0'] = '';
        values['endDate_0'] =  '';
        onSimpleChange('startDate_0', '');
        onSimpleChange('endDate_0', '');
    }
    console.log('====== handleNext', values)

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };


    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };


    const download = (() => {
        finalLink.click();
    })

    return (
        <Box sx={{ width: '100%' }}>
        <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}> 
                {label}
                </StepButton>
            </Step>
            ))}
        </Stepper>
       {errorOccured ? <Alert severity="error">This is an error alert — check it out!</Alert> : ''}
        <>
            {allStepsCompleted() ? (
            <React.Fragment>
                
                All steps completed - you&apos;re finished
                
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
                </Box>
            </React.Fragment>
            ) : (
            <React.Fragment>
                
                   { activeStep === 0 ?<StatsByContratStep0Form contractsData={contracts} onChange={onChange}   onChangeD={onChangeD} queryParam={queryParam} /> :<></>}
                   { activeStep === 1 ?<StatsByContratStep1Form contractsData={contracts}  policiesData={values.policies} onChangePolComp={onChangePolComp} onChangeD={onChangeD}/> :<></>}
                   { activeStep === 2 ?<StatsByContratStep2Form policiesData={values.policies} onSubmit={onSubmit}/> :<></>} 
                
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    sx={{ mr: 1 }}
                >
                    <Link to="/">Back</Link>
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                     Next 
                </Button> */}

                { (!loadingFile && !fileReady) ? ( <React.Fragment> 
                  {  values.validity.find((v: any) => {  return v === false }) === undefined  ?<><Button variant="outlined" color="primary" onClick={onSubmit} sx={{ mr: 1 }}>
                                        GENERER LE RAPPORT
                                    </Button>{ activeStep === 0 ? <Button variant="outlined" color="warning" onClick={handleNext} sx={{ mr: 1 }}>
                                            COMPARER SUR 2 PERIODES
                                        </Button> : ''}</> 
                : '' }
                </React.Fragment>)  : <LinearProgress /> }
                {
                    fileReady && !errorOccured ? <Button size="small" onClick={download}>  Telecharger </Button> : '' }
                
                </Box>
            </React.Fragment>
            )}
        </>
        </Box>
    );
}   

export default StatsByContratForm;


