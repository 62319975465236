import React, { Fragment, useState } from 'react';
import axios from 'axios';

import * as formConsts from '../common/form-const';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import { COUNTRIES } from '../common/countries';
import FormControl from '@mui/material/FormControl';
import CustomAutocomp from '../components/customAutocomplete';
import DatePicker from '../components/basicDatePicker';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useForm } from '../utils/hooks';
import { groupBy } from '../utils/groupBy';
import CircularProgress from '@mui/material/CircularProgress';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Checkbox from '@mui/material/Checkbox/Checkbox';

const Road = styled(Link)({
    textDecoration: 'none',
});


interface MembersFormProps {
    contractsData?: any;
    fileReady?: any;
}


const MembersForm: React.FC<MembersFormProps> = ({ contractsData }) => {
    const [countriesData, setPolicies] = useState([]);
    const [fileReady, setFileStatus] = useState(false);
    const [downloadStarted, setDownloadStarted] = useState(false);
    const [errorOccured, setErrorOccured] = useState(false);
    const [allPolicies, setAllPolicies] = useState(false);
    const [allMembers, setAllMembers] = useState(false);
    const [showInterval, setShowInterval] = useState(false);
    const [finalLink, setLink] = useState(document.createElement('a'));
    let url = '';
    let link: HTMLAnchorElement = document.createElement('a');
    const { values, onChangeCustom, onSimpleChange, onSubmit } = useForm(membersReportCallback, {
        policyholder: '',
        policies: [],
        startDate: '',
        endDate: '',
        language: '',
        regroup:'',
        allMembers: false,
        interval: false,
        checkInterval:false,
        validity: [false]
    });


    function membersReportCallback() {

        let policyIds: any[] = [];
        let sources: any[] = [];
        setDownloadStarted(true);
        values.policies.forEach((policy: any) => {
            const country = COUNTRIES.find((co: any) => {
                return co.countries?.find((country: any) => {
                    return country.value === policy.value.slice(0, 3)
                })
            });
            policyIds.push({
                dbCode: country?.dbCode,
                policy: policy.value.slice(3, policy.length)
            })
        });
        let statP = groupBy(policyIds, 'dbCode');

        for (const [key, value] of Object.entries(statP)) {
            let pol: any[] = [];
            (value as any[]).forEach(el => {
                pol.push(el.policy)
            });
            sources.push({
                dbCode: key,
                policyIds: pol
            });
        }
        const API_URL = process.env.REACT_APP_API_URL || 'http://164.92.171.197:5010';
        axios.post(API_URL + '/reports/members', {
            sources: sources,
            date:  values.startDate,
            regroup: values.regroup.value?? false,
            language: values.language.value,
            startDate: values.startDate,
            endDate: values.endDate,
            allMembers: values.allMembers,
            interval: values.interval,
            policyholder: values.policyholder.name.split('-')[0]
        }, {
            responseType: 'blob'
        }).then((response) => {
            setDownloadStarted(false);
            url = window.URL.createObjectURL(new Blob([response.data]));
            link.href = url;
            link.setAttribute('download', values.policyholder.name + '.xlsx');
            document.body.appendChild(link);
            setLink(link);
            setFileStatus(true);
        }).catch((error) => {
            setErrorOccured(true);
            setDownloadStarted(false);
        });
    }

    const onChange = ((event: any, val: any) => {
        
        setErrorOccured(false);
        const id = String(event.target.id).split('-')[0];
        values[`${String(event.target.id).split('-')[0]}`] = val;
        if (id === 'policyholder') {
            onChangeCustom(event, val.name.split('-')[0]);
            setPolicies(val.policies);
            setFileStatus(false);
            if(allPolicies){
                onChangeCustom('policies',val.policies)
            }
        }
        onChangeCustom(event, val);

    });
    const onChangeD = ((id: string, val: any) => {
        values[id] = moment(val.toString()).format('YYYY-MM-DD');
        onSimpleChange(id, moment(val.toString()).format('YYYY-MM-DD'));
        setErrorOccured(false);
    });

    const handleAllPoliciesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const allPolicies = event.target.checked ;
        setAllPolicies(allPolicies);
        if (allPolicies){
            const policies = values.policyholder?.policies;
            onChangeCustom('policies',policies)
        }else{
            onChangeCustom('policies',[])
        }
      };

      const handleAllMembersChange = (event: React.ChangeEvent<HTMLInputElement>, val: any) => {
        values['allMembers'] = val.value;        
        setAllMembers(val.value);
        onChangeCustom('allMembers',val.value);
      };

      const handleInterval = (event: React.ChangeEvent<HTMLInputElement>, val: any) => {

        onChangeCustom('interval',val.value)
        values['interval'] = val.value;       
        setShowInterval(val.value)
        if(!showInterval){
            values['startDate'] = moment(new Date()).format('YYYY-MM-DD');
            onSimpleChange('startDate', moment(new Date()).format('YYYY-MM-DD'));
            
            values['endDate'] = moment(new Date()).format('YYYY-MM-DD');
            onSimpleChange('endDate', moment(new Date()).format('YYYY-MM-DD'));
        }

        };

    const download = (() => {
        finalLink.click();
    })

    return (
        <Fragment>
            {errorOccured ? <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Une erreur est survenue  — <strong>Veuillez contacter l'administrateur!</strong>
            </Alert> : null}
            <Paper sx={{ marginTop: 3 }}>
                <FormControl component="fieldset">
                    <FormLabel component="legend" >Données allant du ** au **</FormLabel>
                    <Grid container component="form" >
                        <Grid item xs={6}>
                            <FormControl sx={{ m: 2 }} >
                                <CustomAutocomp id='policyholder' label='Souscripteur' multiple={false} dataArray={contractsData} handleValueSelected={onChange} />
                                <FormHelperText id="outlined-weight-helper-text">Selectionner le souscripteur</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ m: 2 }}>
                            {!allPolicies ? (
                                    <>
                                        <CustomAutocomp id='policies' label='Pays' multiple={true} dataArray={countriesData} handleValueSelected={onChange} filterSelectedOptions={true} />
                                        <FormHelperText id="outlined-weight-helper-text">Pays du rapport</FormHelperText>
                                        <FormControlLabel
                                            control={<Checkbox checked={allPolicies} onChange={handleAllPoliciesChange} name="allPolicies" />}
                                            label="Pour toutes les polices du contrat?"
                                        />
                                    </>
                                    ) : (
                                    <FormControlLabel
                                        control={<Checkbox checked={allPolicies} onChange={handleAllPoliciesChange} name="allPolicies" />}
                                        label="Pour toutes les polices du contrat?"
                                    />
                                    )}

                            </FormControl>

                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ m: 2 }}>
                                <CustomAutocomp id='allMembers' label="Tous ou uniquement actifs" dataArray={formConsts.AllMembersOrActive} handleValueSelected={handleAllMembersChange} />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ m: 2 }}>
                                <CustomAutocomp id='nowOrInterval' label="Date ou Intervalle" dataArray={formConsts.NowOrInterval} handleValueSelected={handleInterval} />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ m: 2 }} >
                                <CustomAutocomp id='language' label='Langue du Rapport' dataArray={formConsts.Languages} handleValueSelected={onChange} />
                                <FormHelperText id="outlined-weight-helper-text">Langue</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ m: 2 }} >
                                <CustomAutocomp id='regroup' label='Repartir/Regrouper' dataArray={formConsts.YesOrNo} handleValueSelected={onChange} />
                                <FormHelperText id="outlined-weight-helper-text">Regrouper (oui) les polices d'un régime sur une seule page</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            
                        {showInterval ? (
                                    <>
                                    <FormControl sx={{ m: 2 }}>
                                        <DatePicker id='startDate' label='Date début' handleValueSelected={onChangeD} />
                                        <FormHelperText id="outlined-weight-helper-text">Indiquer la date de début</FormHelperText>
                                    </FormControl>
                                    </>
                                    ) : <></>}

                        </Grid>
                        <Grid item xs={6}>
                            
                        {showInterval ? (
                                    <>
                                    <FormControl sx={{ m: 2 }}>
                                        <DatePicker id='endDate' label='Date fin' handleValueSelected={onChangeD} />
                                        <FormHelperText id="outlined-weight-helper-text">Indiquer la date de fin</FormHelperText>
                                    </FormControl>
                                    </>
                                    ) : <></>}

                        </Grid>

                    </Grid>
                </FormControl>
                <Box sx={{ alignSelf: 'flex-end', alignContent: "flex-end", marginLeft: 'auto' }}>
                    {values.validity.find((v: any) => v === false) === undefined ? <Button size="small" onClick={onSubmit}>  Valider </Button> : ''}
                    {downloadStarted ? <CircularProgress color="inherit" size={20} /> : null}
                    {fileReady ? <Button size="small" onClick={download}>  Telecharger </Button> : ''}
                    <Road to='/' >
                        <Button size="small">  Annuler </Button>
                    </Road>
                </Box>
            </Paper>
        </Fragment>
    );

}

export default MembersForm;