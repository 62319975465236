import React, { ChangeEventHandler, useState } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';

import * as formConsts from '../common/form-const';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import { COUNTRIES } from '../common/countries';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CustomAutocomp from './customAutocomplete';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import DatePicker from './basicDatePicker';
import { useForm } from '../utils/hooks';
import { groupBy } from '../utils/groupBy';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Checkbox from '@mui/material/Checkbox/Checkbox';


const token = localStorage.getItem('token');
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});

interface StatsByContratStep0FormProps {
    contractsData?: any;
    policiesData?: any;
    queryParam?: any;
    handleNext?: any;
    onChange?: any;
    onChangeD?: any;
    fileReady?: any;
}

type PremiumField = {
    id: string,
    premium: number
  }

const StatsByContratStep0Form: React.FC<StatsByContratStep0FormProps> = ({ contractsData, onChange, onChangeD, queryParam }) => {

    const API_URL = process.env.REACT_APP_API_URL || 'http://164.92.171.197:5010';
    const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
    //const [policiesData, setPolicies] = useState([]);
    const [errorOccured, setErrorOccured] = useState(false);
    const [premiumsFields, setShowPremiumFields] = useState<PremiumField[]>([]);
    const [reportType, setReportType] = useState(queryParam);
    const [reportCountry, setCountry] = useState('');
    const [countryPolicies, setCountryPolicies] = useState<any[]>([]);
    const [countryPoliciesList, setCountryPoliciesList] = useState<any[]>([]);
    const [allPolicies, setAllPolicies] = useState(false);
    const [finalLink, setLink] = useState(document.createElement('a'));
    let url = '';
    let link: HTMLAnchorElement = document.createElement('a');


    const { values, onChangeCustom, onSimpleChange, onSubmit } = useForm(StatsByContratCallback, {
        policyholder: '',
        policies: [],
        policiesPremiums: [],
        startDate: '',
        endDate: '',
        startExeDate: '',
        endExeDate: '',
        language: '',
        currency: '',
        model: '',
        country: 'INT',
        ModelReportFormatStatistics: 0,
        fakePolicyholder: Number(queryParam) === 2,
        policiesComparison: [],
        allPolicies: false,
        validity: [false]
    });

    const onChangePolicies = ((event: any, value: any) => {
        
        let policiesArray: any[]  = [];
        let polPrem: any[]= [];

        value.forEach((element:any) => {
            console.log('event from onChangeReportType', event, value,values,reportCountry, element);
            policiesArray.push(reportCountry.concat( element.value))
            polPrem.push({
                value: reportCountry.concat( element.value)
            })
        });
        onChange('policyholder','-');
        onChange('fakePolicyholder',true);
        onChange('policies',policiesArray);
        setCountryPolicies(polPrem);
        console.log("===========================",polPrem)
        
    });


    const handleAllPoliciesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('0.',allPolicies)
        const checked = event.target.checked ;
        console.log('1.',checked)
        setAllPolicies(checked);
        onChange('allPolicies', event.target.checked);
        console.log('2.',allPolicies, values.allPolicies)
      };

const onChangePolicyholder = ((event: any, value: any) => {
        onChange(event, value);
        onChangeCustom('policyholder', value.value);
        setCountryPolicies(value.policies);
        onChangeCustom( 'policies' , value.policies);
    });

const chooseCountryPolicies = ((event: any, value: any) => {
        console.log('event from chooseCountryPolicies', event, value);
        setCountry(value.dbCode);
        setCountryPolicies([]);
        onChange('policyholder','-');
        onChange('country',value.dbCode);
        axios.post(API_URL + '/policies/country', {
            dbCode: value.dbCode
        },{
            headers: {
              Authorization: `Bearer ${token}`,
            },}).then((response) => {
            // setCountryPolicies(response.data)
            setCountryPoliciesList(response.data)
        })
    });

    const addPremiums: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event: any) => {
        const newId = event.target.id;
        const newPremium = Number(event.target.value);
        const indexFound = premiumsFields.findIndex((premium) => premium.id === newId);
        const newPremiumFields = [...premiumsFields];
        if (indexFound !== -1) {
          newPremiumFields[indexFound] = {
            id: newId,
            premium: newPremium,
          };
        } else {
          newPremiumFields.push({
            id: newId,
            premium: newPremium,
          });
        }
        setShowPremiumFields(newPremiumFields)
        onChange('policiesPremiums',newPremiumFields)
    };

    function StatsByContratCallback() {

        let policyIds: any[] = [];
        let sources: any[] = [];
        console.log('before calling the server', values)
        if(values.allPolicies){
            policyIds.push({
                dbCode: reportCountry,
                policy: 0,
                allPolicies

            })
        }else{
            values.policies.forEach((policy: any) => {
                const country = COUNTRIES.find((co: any) => {
                    return co.countries?.find((country: any) => {
                        return country.value === policy.value.slice(0, 3)
                    })
                });
                policyIds.push({
                    dbCode: country?.dbCode,
                    policy: policy.value.slice(3, policy.length)
                })
            });

        }
        let statP = groupBy(policyIds, 'dbCode');

        for (const [key, value] of Object.entries(statP)) {
            let pol: any[] = [];
            (value as any[]).forEach(el => {
                pol.push(el.policy)
            });
            sources.push({
                dbCode: key,
                policyIds: pol,
                allPolicies
            });
        }

    }

    return (
        <div className='container'>
            <Grid item xs={12}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}  justifyContent="space-between">
                    <Grid item xs={6}>
                        { Number(queryParam)=== 2 ? 
                        // <FormControl sx={{ m: 2 }}>
                        <FormControl fullWidth>
                            <CustomAutocomp id='country' label='Countries' multiple={false} dataArray={COUNTRIES} handleValueSelected={chooseCountryPolicies} />
                            <FormHelperText id="outlined-weight-helper-text">Selectionner le pays</FormHelperText>
                        </FormControl>
                        : 
                        // <FormControl sx={{ m: 2 }}>
                        <FormControl fullWidth>
                            <CustomAutocomp id='policyholder' label='Souscripteur' multiple={false} dataArray={contractsData} handleValueSelected={onChangePolicyholder} />
                            <FormHelperText id="outlined-weight-helper-text">Selectionner le souscripteur</FormHelperText>
                        </FormControl>}
                    </Grid>
                    <Grid item xs={4}>
                        { Number(queryParam) === 1 ? <FormControl sx={{ m: 2 }}>
                        <></>
                        </FormControl> : <FormControl sx={{ m: 2 }}>
                        <CustomAutocomp id='countryPolicies' label='Polices' multiple={true} dataArray={countryPoliciesList} handleValueSelected={onChangePolicies} />
                        <FormHelperText id="outlined-weight-helper-text">Selectionner les polices</FormHelperText>
                                    <FormControlLabel
                                        control={<Checkbox checked={allPolicies} onChange={handleAllPoliciesChange} name="allPolicies" />}
                                        label="Toutes les polices de la base?"
                                    />
                        </FormControl>}
                    </Grid>
                    <Grid item xs={6}>
                        
                        <FormControl fullWidth>
                            <DatePicker id='startDate' label='Date début période' handleValueSelected={onChangeD} />
                            <CustomWidthTooltip title="La date selectionnée concerne l'intervalle le plus récent">
                                <FormHelperText id="outlined-weight-helper-text">Indiquer la date de début de la période à analyser</FormHelperText>
                            </CustomWidthTooltip>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        
                        <FormControl fullWidth>
                            <DatePicker id='endDate' label='Date fin période' handleValueSelected={onChangeD} />
                            <CustomWidthTooltip title="La date selectionnée concerne l'intervalle le plus récent">
                                <FormHelperText id="outlined-weight-helper-text">Indiquer la date de fin  de la période à analyser</FormHelperText>
                            </CustomWidthTooltip>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        
                        <FormControl fullWidth>
                            <CustomAutocomp id='language' label='Langue du Rapport' dataArray={formConsts.reportLanguages} handleValueSelected={onChange} />
                            <FormHelperText id="outlined-weight-helper-text">Langue</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                    { Number(queryParam) !== 2 ? 
                        <FormControl fullWidth>
                            <CustomAutocomp id='model' label='Modèle' dataArray={formConsts.ModelReport} handleValueSelected={onChange} />
                            <FormHelperText id="outlined-weight-helper-text">Choisir la mise en forme souhaitée : ASCOMA ou PACTILIS</FormHelperText>
                        </FormControl>: <FormControl fullWidth>
                            <TextField id='model' label='Modèle'  disabled={true} defaultValue='ASCOMA'  sx={{ "& .MuiInputBase-root": { fontSize: "inherit" } }}/>
                            <FormHelperText id="outlined-weight-helper-text">Choisir la mise en forme souhaitée : ASCOMA ou PACTILIS</FormHelperText>
                        </FormControl>}
                    </Grid>
                    <Grid item xs={6}>
                        
                        <FormControl fullWidth>
                            <CustomAutocomp id='currency' label='Devises' dataArray={formConsts.Currency} handleValueSelected={onChange} />
                            <FormHelperText id="outlined-weight-helper-text">Choix de la devise</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        
                        <FormControl fullWidth>
                            <CustomAutocomp id='modelReportFormatStatistics' label='Modèle du Rapport' dataArray={formConsts.ModelReportFormatStatistics} handleValueSelected={onChange} />
                            <FormHelperText id="outlined-weight-helper-text">Modèle du Rapport</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        
                        <FormControl >
                            <DatePicker id='startExeDate' label='Date Début contrat' handleValueSelected={onChangeD} />
                            <FormHelperText id="outlined-weight-helper-text">Date de Début d'exercice (*)</FormHelperText>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={4}>
                        
                        <FormControl >
                            <DatePicker id='endExeDate' label='Date fin contrat' handleValueSelected={onChangeD} />
                            <FormHelperText id="outlined-weight-helper-text">Date de fin d'exercice(*)</FormHelperText>
                        </FormControl>
                    </Grid>
                    { countryPolicies.length !== 0 && 
                        
                        <Grid item xs={12}>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="space-between">
                            {countryPolicies.map((policy: { value: React.Key }, index: number) => {
                            return (
                            <Grid item key={policy.value}  xs={4}>
                            
                                <FormControl fullWidth>
                                    <TextField id={policy.value.toString()}  sx={{ "& .MuiInputBase-root": { fontSize: "inherit" } }} label='Prime' type="number" InputLabelProps={{ shrink: true }} onChange={addPremiums} defaultValue={0} />
                                    <FormHelperText id="outlined-weight-helper-text">La prime renseignée est pour la police: <strong>{policy.value}</strong></FormHelperText>
                                </FormControl>
                            </Grid>
                            )
                            })}
                        </Grid> 
                        </Grid>
                        }
                </Grid>
            </Grid>
    </div>
    );
}

export default StatsByContratStep0Form;


