import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions }  from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';


interface Data {
  name: string;
  value: string;
}


interface AutocompleteProps {
  id: string | any;
  label: string | any;
  value?: any;
  filterSelectedOptions?: boolean;
  handleValueSelected? : any;
  dataArray?:  Data[] | any;
  multiple?: boolean;
  disabled?: boolean;
  selectedOptions?: any;
  children?: any;
  defaultValue?: any;
}


const CustomAutocomplete: React.FC<AutocompleteProps> = ({ id,label, multiple, dataArray,handleValueSelected, filterSelectedOptions, selectedOptions, defaultValue}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Data[]>([]);
  
  const [filterOption, setFilterOption] = React.useState(false);
  const loading = open && options.length === 0;
  const maxSelections = 5;
  const selectedOpt = selectedOptions ?? [];

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    if(filterSelectedOptions){
      setFilterOption(filterSelectedOptions)
    }

    (async () => {
      if (active) {
        dataArray?.length > 0 ? setOptions([...dataArray]) :  setOptions([]) ;
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, dataArray, filterSelectedOptions]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: Data) => option.name,
  });

  return (
    <Autocomplete
      id={id}
      multiple={multiple}
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      clearOnBlur
      onChange={handleValueSelected}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name || ""}
      options={options}
      defaultValue={defaultValue}
      filterSelectedOptions={filterOption}
      filterOptions={filterOptions}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default CustomAutocomplete;

