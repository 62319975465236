import { DbCode } from './db-code';

export const COUNTRIES = [
  {
    dbCode: DbCode.BDI,
    name: 'Burundi',
    countries: [{
      value: 'BDI',
      name: 'Burundi'
    }]
  },
  {
    dbCode: DbCode.CAF,
    name: 'Centrafrique',
    countries: [{
      value: 'CAF',
      name: 'Centrafrique'
    }]
  },
  {
    dbCode: DbCode.CIV,
    name: "Côte d'Ivoire",
    countries: [{
      value: 'CIV',
      name: 'Côte d\'Ivoire'
    }]
  },
  {
    dbCode: DbCode.GNQ,
    name: 'Guinée Equatoriale',
    countries: [{
      value: 'GNQ',
      name: 'Guinée Equatoriale'
    }]
  },
  {
    dbCode: DbCode.MRT,
    name: 'Mauritanie',
    countries: [{
      value: 'MRT',
      name: 'Mauritanie'
    }]
  },
  {
    dbCode: DbCode.TCD,
    name: 'Tchad',
    countries: [{
      value: 'TCD',
      name: 'Tchad'
    }]
  },
  {
    dbCode: DbCode.COD,
    name: 'RDC',
    countries: [{
      value: 'COD',
      name: 'RDC'
    }]
  },
  {
    dbCode: DbCode.TGO,
    name: 'Togo',
    countries: [{
      value: 'TGO',
      name: 'Togo'
    }]
  },
  {
    dbCode: DbCode.BEN,
    name: 'Bénin',
    countries: [{
      value: 'BEN',
      name: 'Bénin'
    }]
  },
  {
    dbCode: DbCode.MDG,
    name: 'Madagascar',
    countries: [{
      value: 'MDG',
      name: 'Madagascar'
    }]
  },
  {
    dbCode: DbCode.COG,
    name: 'Congo',
    countries: [{
      value: 'COG',
      name: 'Congo'
    }]
  },
  {
    dbCode: DbCode.CMR,
    name: 'Cameroun',
    countries: [{
      value: 'CMR',
      name: 'Cameroun'
    }]
  },
  {
    dbCode: DbCode.SEN,
    name: 'Sénégal',
    countries: [{
      value: 'SEN',
      name: 'Sénégal'
    }]
  },
  {
    dbCode: DbCode.GAB,
    name: 'Gabon',
    countries: [{
      value: 'GAB',
      name: 'Gabon'
    }]
  },
  {
    dbCode: DbCode.UNI,
    name: 'Pactilis Bénin / Rwanda',
    countries: [{
      value: 'UNI',
      name: 'Bénin'
    }],
  },
  {
    dbCode: DbCode.PCM,
    name: 'G4S Cameroun',
    countries: [{
      value: 'CMR',
      name: 'G4S Cameroun'
    }]
  },
  {
    dbCode: DbCode.MLI,
    name: 'Mali',
    countries: [{
      value: 'MLI',
      name: 'Mali'
    }]
  },
  {
    dbCode: DbCode.LBR,
    name: 'Libéria',
    countries: [{
      value: 'LBR',
      name: 'Libéria'
    }]
  },
  {
    dbCode: DbCode.NER,
    name: 'Niger',
    countries: [{
      value: 'NER',
      name: 'Niger'
    }]
  },
  {
    dbCode: DbCode.GIN,
    name: 'Guinée',
    countries: [{
      value: 'GIN',
      name: 'Guinée'
    }]
  },
  {
    dbCode: DbCode.BFA,
    name: 'Burkina Faso',
    countries: [{
      value: 'BFA',
      name: 'Burkina Faso'
    }]
  },
  {
    dbCode: DbCode.GHA,
    name: 'Ghana',
    countries: [{
      value: 'GHA',
      name: 'Ghana'
    }]
  },
  {
    dbCode: DbCode.RWA,
    name: 'Rwanda',
    countries: [{
      value: 'RWA',
      name: 'Rwanda'
    }]
  }
];
