export function groupBy(objectArray: any, property:string) {
    return objectArray.reduce(function (acc: { [x: string]: any[] }, obj: { [x: string]: any }) {
      let key = obj[property]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
  }
export function isEmailValid(email:any) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }