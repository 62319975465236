import React , { Fragment } from 'react';
import SubMenuStatsGrid from '../components/submenustats-grid'; 


interface submenustatsGridProps  {}

const submenustatsGrid: React.FC<submenustatsGridProps> = () => {

    return (
        <Fragment>            
            <SubMenuStatsGrid />
        </Fragment>
    )

  }

  export default submenustatsGrid;

