import React , { Fragment } from 'react';
import SubMenuClaimsGrid from '../components/submenustats-claims-grid';


interface submenuclaimsGridProps  {}

const submenustatsGrid: React.FC<submenuclaimsGridProps> = () => {

    return (
        <Fragment>            
            <SubMenuClaimsGrid />
        </Fragment>
    )

  }

  export default submenustatsGrid;