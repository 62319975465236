import React,{ Fragment } from 'react';
import { Routes, Route, useSearchParams } from "react-router-dom";

import Navbar from '../components/navbar';
import  PagesContainer  from '../components';
import MainMenu from './mainMenu';
import SubMenuStats from './subMenuStats';
import Contact from './Contact';




function Home() {


    
    return (
    <>
        <Routes >
            <Route path="/" element={<MainMenu />} > 
            </Route>
        </Routes>
    </>
    );
  }

export default Home;